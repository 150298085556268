import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types"
import React,{ useState, useEffect } from "react"
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Image from "./image"
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import HomeIcon from '@material-ui/icons/Home';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'sticky',
    zIndex: 5,
    top: 0, 
  },
  paper: {   
    backgroundColor: "rgb(0,0,0,0)",
    color: "white"
  },
  paper2: {   
    backgroundColor: "white"
  },
}));


const HeaderXs = ({mode,isLoggedIn,handleLogout}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar variant="regular" className={mode === "dark" ? classes.paper: classes.paper2}>
    <Typography variant="h3" style={{width: "20%"}}>
      <Link
        to="/"
        style={{
          textDecoration: `none`,
          color: 'gray',
        }}
      >
        <div style={{flexGrow: 1, minWidth: "200px",maxWidth: "330px"}}>
          <Image filename="logo.png" />
        </div>
      </Link>
    </Typography>
    <Typography variant="h6" style={{flexGrow: 1,textAlign: "right"}}>
      <IconButton edge="start" style={{backgroundColor: "#3f51b5", color: "white"}}  aria-label="menu" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to="/"
        >
          <ListItemIcon>
            <HomeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Top"/>
        </MenuItem>
        {
          isLoggedIn && (
            <MenuItem
              component={Link}
              to="/app/prediction"
            >
              <ListItemIcon>
                <EmojiObjectsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="home"/>
            </MenuItem> 
          )
        }
        <MenuItem
          component={Link}
          to="/overview"
        >
          <ListItemIcon>
            <FavoriteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="概要"/>
        </MenuItem> 
        <MenuItem
          component={Link}
          to="/contact"
          style={{backgroundColor: '#3f51b5', color: 'white'}}
        >
          <ListItemIcon>
            <ContactSupportIcon fontSize="small" style= {{color: 'white'}} />
          </ListItemIcon>
          <ListItemText primary="お問合せ"/>
        </MenuItem>
        
        {
          isLoggedIn ? (
            <MenuItem
              onClick={handleLogout}
              style= {{backgroundColor: '#f50057', color: 'white'}}
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" style= {{color: 'white'}}/>
              </ListItemIcon>
              <ListItemText primary="ログアウト"/>
            </MenuItem>
          ):( 
            <MenuItem
              component={Link}
              to={`${process.env.GATSBY_AWS_COGNITO_LOGIN}`}
              style= {{backgroundColor: '#f50057', color: 'white'}}
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" style= {{color: 'white'}}/>
              </ListItemIcon>
              <ListItemText primary="ログイン"/>
            </MenuItem>
          )
        }
      </Menu>

    </Typography>
  </Toolbar>
  )

};

const HeaderMd = ({ siteTitle,pathname,mode,isLoggedIn,handleLogout }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Toolbar className={mode === "dark" ? classes.paper: classes.paper2}>
      <Typography variant="h3" style={{width: "25%"}}>
        <Link
          to="/"
          style={{
            textDecoration: `none`,
            color: 'gray',
          }}
        >
          <div style={{flexGrow: 1, minWidth: "220px",maxWidth: "250px"}}>
            <Image filename="logo.png" />
          </div>
        </Link>
      </Typography>

      {pathname ==="/" &&
        <>
        <Typography variant="h6" style={{flexGrow: 1}}>
          <Tabs
            style={{float: "right"}}
            TabIndicatorProps={{style: {background: mode==="dark" ? 'white':""}}}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            centered
          >
            <Tab style={{fontSize: 16, fontWeight: "bold"}} label="TOP" key="TOP" to="/" component={Link} ></Tab>
            <Tab style={{fontSize: 16, fontWeight: "bold"}} label="特徴" key="特徴" to="/#features" component={Link} />
            <Link to="/contact/" style={{paddingLeft: 10,textDecoration: "none", paddingRight: 5}} >

                <Button style={{fontSize: 16}} variant="contained" color="primary" endIcon={<ContactSupportIcon />}>お問合せ</Button>

            </Link>
            <a href= {`${process.env.GATSBY_AWS_COGNITO_LOGIN}`} rel="noreferrer" style = {{paddingLeft: 5,textDecoration: "none", paddingRight: 10}}>
                <Button style={{fontSize: 16}} variant="contained" color="secondary" endIcon={<PersonIcon />}>ログイン</Button>

            </a>
          </Tabs>
        </Typography>
        </>
      }

      {pathname!=="/" &&
        <div style={{flexGrow: 1,textAlign: "right"}}>
          <Link
            style={{textDecoration: "none"}}
            to="/"> 
            <p style={{fontSize: "20px", color: mode==="dark" ? "white": ""}}>
              <strong>TOPへ</strong>
            </p>
          </Link>
        </div>
      }
      
      
    </Toolbar>
  )
};


const Header = ({ siteTitle,pathname,breakpoints,isLoggedIn,handleLogout }) => {
  const classes = useStyles();
  const [mode, setMode] = useState("dark");
  const listenScrollEvent = event => {
    if (window.scrollY < 5) {
      return setMode("dark");
    } else if (window.scrollY > 35) {
      return setMode("light");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <header className={classes.header}>

        { 
          breakpoints?.md ? <HeaderXs  mode={mode} isLoggedIn = {isLoggedIn} handleLogout={handleLogout}/> : null
        }
        { 
          breakpoints?.l ? <HeaderMd siteTitle={siteTitle} mode={mode} pathname={pathname} isLoggedIn = {isLoggedIn} handleLogout={handleLogout} /> : null  
        }
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
